import { useEffect } from "react";
import { navigate } from "gatsby";
import { getParameterByName } from "../common/utils";
import { Q4E_STORAGE_KEY } from "../constants";

const Q4E = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const id = getParameterByName("id");
      if (id) {
        localStorage.setItem(Q4E_STORAGE_KEY, id);
        navigate("/");
      }
    }
  }, []);

  return null;
};

export default Q4E;
